var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "400", scrollable: "" },
      model: {
        value: _vm.properties.visible,
        callback: function ($$v) {
          _vm.$set(_vm.properties, "visible", $$v)
        },
        expression: "properties.visible",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "card" },
        [
          _c(
            "v-card-title",
            { staticClass: "headline secondary-bg" },
            [
              _c("v-icon", [_vm._v("place")]),
              _vm._v("  Select " + _vm._s(_vm.label()) + "\n            "),
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-icon", { on: { click: _vm.close } }, [_vm._v("close")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "card-body" },
            [
              _c(
                "v-list",
                _vm._l(_vm.items, function (item) {
                  return _c(
                    "v-list-tile",
                    {
                      key: item.id,
                      staticClass: "list-item",
                      on: {
                        click: function ($event) {
                          return _vm.itemSelected(item)
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-tile-content",
                        [
                          _c("v-list-tile-title", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.name) +
                                "\n                        "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }