var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-list",
    [
      _vm._l(_vm.favorites, function (fav, index) {
        return [
          _c(
            "v-list-tile",
            {
              key: index,
              style: {
                "background-color":
                  _vm.removeMode && _vm.removeSelected.includes(index)
                    ? "#ffb3b3"
                    : index % 2 === 0
                    ? "#fff"
                    : "#fafafa",
              },
              attrs: { avatar: "" },
              on: {
                click: function ($event) {
                  return _vm.selectFavorite(fav, index)
                },
              },
            },
            [
              _c(
                "v-list-tile-avatar",
                [
                  _vm.removeMode && _vm.removeSelected.includes(index)
                    ? _c("v-icon", [_vm._v("delete")])
                    : _vm.reorderMode && index === 0
                    ? _c("v-icon")
                    : _vm.reorderMode
                    ? _c(
                        "v-icon",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.reorderUp(index)
                            },
                          },
                        },
                        [_vm._v("arrow_upward")]
                      )
                    : _c("v-icon", [_vm._v(_vm._s(fav.icon))]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-tile-content",
                [
                  _c("v-list-tile-title", { staticClass: "list-title" }, [
                    _c("span", { staticClass: "list-title-label" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(fav.label) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    fav.type === _vm.FAVORITE_TYPE_TRANSIT
                      ? _c(
                          "span",
                          { staticClass: "list-title-badge" },
                          [
                            fav.eventCount === 0
                              ? [
                                  _c(
                                    "span",
                                    { staticClass: "list-title-badge-good" },
                                    [_c("v-icon", [_vm._v("check_circle")])],
                                    1
                                  ),
                                ]
                              : fav.eventCount > 0
                              ? [
                                  _c(
                                    "span",
                                    { staticClass: "list-title-badge-bad" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(fav.eventCount) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-tile-action",
                [
                  _vm.removeMode
                    ? _c("v-icon")
                    : _vm.reorderMode && index === _vm.favorites.length - 1
                    ? _c("v-icon")
                    : _vm.reorderMode
                    ? _c(
                        "v-icon",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.reorderDown(index)
                            },
                          },
                        },
                        [_vm._v("arrow_downward")]
                      )
                    : _c("v-icon", [_vm._v("chevron_right")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          index !== _vm.favorites.length - 1
            ? _c("v-divider", { key: "div-" + index })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }