var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "page" },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "headline secondary-bg",
              staticStyle: { padding: "8px 16px" },
            },
            [
              _c("v-icon", [_vm._v("swap_horiz")]),
              _vm._v("  "),
              _vm.selectedDirection
                ? _c("span", {
                    domProps: {
                      innerHTML: _vm._s(_vm.selectedDirection.label),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { flat: "", icon: "" },
                  on: {
                    click: function ($event) {
                      _vm.directionSelectionDialogProps.visible =
                        !_vm.directionSelectionDialogProps.visible
                    },
                  },
                },
                [_c("v-icon", [_vm._v("filter_list")])],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "departures-header" }, [
            _c(
              "div",
              { staticClass: "departures-header-item departures-header-time" },
              [_vm._v("Departure")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "departures-header-item departures-header-destination",
              },
              [_vm._v("Destination")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "departures-header-item departures-header-status",
              },
              [_vm._v("Status")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "departures-header-item departures-header-track" },
              [_vm._v("Track")]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass: "departures-header-item departures-header-peak",
            }),
          ]),
          _vm._v(" "),
          _vm._l(_vm.displayedDepartures, function (departure) {
            return _c("rt-departure-item", {
              key: _vm.stop.name + "-departure-" + departure.trip.id,
              attrs: { departure: departure, station: _vm.stop },
            })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("rt-direction-selection-dialog", {
        attrs: {
          properties: _vm.directionSelectionDialogProps,
          directions: _vm.directions,
          selectedDirection: _vm.selectedDirection,
          includeTerminal: _vm.includeTerminal,
        },
        on: {
          directionSelected: _vm.onDirectionSelected,
          includeTerminalToggled: _vm.onIncludeTerminalToggled,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }