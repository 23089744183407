var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "page" },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline secondary-bg" },
            [
              _c("v-icon", [_vm._v("access_time")]),
              _vm._v("  Stations\n        "),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card-text", [
            _c("p", { staticClass: "subheading" }, [
              _vm._v(
                "Select a Station below to view upcoming departures and track assignments."
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-button-container" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      disabled: !_vm.dialogProps.stops,
                      color: "primary",
                      depressed: "",
                    },
                    on: {
                      click: function ($event) {
                        _vm.dialogProps.visible = true
                      },
                    },
                  },
                  [
                    _c("v-icon", [_vm._v("place")]),
                    _vm._v("  \n                    "),
                    _vm.station
                      ? _c("span", [_vm._v(_vm._s(_vm.station.name))])
                      : _c("span", [_vm._v("Select Station")]),
                    _vm._v("\n                      "),
                    _c("v-icon", [_vm._v("arrow_drop_down")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "stations-button-container" },
        [
          _c(
            "v-btn",
            {
              attrs: { disabled: !_vm.station, color: "primary" },
              on: { click: _vm.showStation },
            },
            [_vm._v("\n            Display\n        ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("rt-stop-selection-dialog", {
        attrs: { properties: _vm.dialogProps },
        on: { stopSelected: _vm.onStopSelected },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }