var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-slide-y-transition",
        [
          _vm.update.isAvailable
            ? _c(
                "v-list",
                { staticClass: "py-0 secondary-bg", attrs: { "two-line": "" } },
                [
                  _c(
                    "v-list-tile",
                    { on: { click: _vm.startUpdate } },
                    [
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("save_alt")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-tile-content",
                        [
                          _c("v-list-tile-title", [_vm._v("Database Update")]),
                          _vm._v(" "),
                          _c("v-list-tile-sub-title", [
                            _vm._v("Download and Install"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-slide-y-transition",
        [
          _vm.appUpdate.isAvailable
            ? _c(
                "v-list",
                { staticClass: "py-0 secondary-bg", attrs: { "two-line": "" } },
                [
                  _c(
                    "v-list-tile",
                    { on: { click: _vm.startAppUpdate } },
                    [
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("refresh")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-tile-content",
                        [
                          _c("v-list-tile-title", [_vm._v("App Update")]),
                          _vm._v(" "),
                          _c("v-list-tile-sub-title", [
                            _vm._v("Reload App to Install"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.auth.display
        ? _c(
            "v-list",
            { staticClass: "py-0" },
            [
              _c(
                "v-list-tile",
                { on: { click: _vm.startAuth } },
                [
                  _c(
                    "v-list-tile-action",
                    [
                      _vm.auth.isLoggedIn
                        ? _c("v-icon", [_vm._v("person")])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.auth.isLoggedIn
                        ? _c("v-icon", [_vm._v("person_outline")])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-tile-content",
                    [
                      _vm.auth.isLoggedIn
                        ? _c("v-list-tile-title", [_vm._v("Log Out")])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.auth.isLoggedIn
                        ? _c("v-list-tile-title", [_vm._v("Log In")])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-list",
        { staticClass: "pt-0", attrs: { subheader: "" } },
        [
          _vm._l(_vm.menu, function (item, index) {
            return [
              item.type === "divider"
                ? _c("v-divider", { key: "div-" + index })
                : _vm._e(),
              _vm._v(" "),
              item.type === "item"
                ? _c(
                    "v-list-tile",
                    {
                      key: "item-" + index,
                      class: {
                        "active-menu-item": _vm.isActiveMenuItem(item, "item"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.drawerLink(item)
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-tile-content",
                        [_c("v-list-tile-title", [_vm._v(_vm._s(item.title))])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              item.type === "alerts"
                ? _c(
                    "v-list-tile",
                    {
                      key: "alert-" + index,
                      class: {
                        "active-menu-item": _vm.isActiveMenuItem(item, "item"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.drawerLink(item)
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-tile-action",
                        [
                          _vm.transitAlertCount && _vm.transitAlertCount > 0
                            ? _c("v-icon", [_vm._v("warning")])
                            : _c("v-icon", [_vm._v("check_circle")]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-tile-content",
                        [
                          _c("v-list-tile-title", [_vm._v(_vm._s(item.title))]),
                          _vm._v(" "),
                          _vm.transitAlertCount &&
                          _vm.transitAlertCount > 0 &&
                          !_vm.isActiveMenuItem(item, "item")
                            ? _c(
                                "span",
                                { staticClass: "alerts-badge primary-bg" },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(_vm.transitAlertCount) +
                                      "\n                  "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              item.type === "favorites" &&
              _vm.favorites &&
              _vm.favorites.length > 0
                ? _c(
                    "div",
                    { key: "fav-" + index },
                    [
                      _c("v-divider"),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel",
                        {
                          staticClass: "favorites-expansion-panel",
                          model: {
                            value: _vm.favoritesExpansion,
                            callback: function ($$v) {
                              _vm.favoritesExpansion = $$v
                            },
                            expression: "favoritesExpansion",
                          },
                        },
                        [
                          _c(
                            "v-expansion-panel-content",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-subheader",
                                          {
                                            staticClass:
                                              "favorites-list-subheader",
                                          },
                                          [_vm._v("Favorites")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _vm._l(_vm.favorites, function (favorite, index) {
                                return [
                                  _c(
                                    "v-list-tile",
                                    {
                                      key: "fav-item-" + index,
                                      class: {
                                        "active-menu-item":
                                          _vm.isActiveMenuItem(
                                            favorite,
                                            "favorite"
                                          ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.favoriteLink(favorite)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-tile-action",
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(favorite.icon)),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c(
                                            "v-list-tile-title",
                                            {
                                              staticClass:
                                                "favorites-list-label-container",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "favorites-list-label-text",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(favorite.label) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }