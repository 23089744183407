var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        {
          style: {
            "background-color":
              _vm.departure.status.status.toLowerCase() === "departed"
                ? "rgba(1, 1, 1, 0.5)"
                : "rgba(0, 0, 0, 0.5)",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "departure-wrapper",
              style: {
                "background-color": "#" + _vm.departure.trip.route.color,
                background:
                  "linear-gradient(to bottom, #" +
                  _vm.departure.trip.route.color +
                  "dd, #" +
                  _vm.departure.trip.route.color +
                  "bb)",
                color: "#" + _vm.departure.trip.route.textColor,
                opacity:
                  _vm.departure.status.status.toLowerCase() === "departed"
                    ? "0.5"
                    : "1",
              },
              on: { click: _vm.selectDeparture },
            },
            [
              _c("div", { staticClass: "departure-item departure-time" }, [
                _vm.departure.status.delay > 0
                  ? _c("span", { staticClass: "departure-item-estimated" }, [
                      _c(
                        "span",
                        { staticStyle: { "text-decoration": "line-through" } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.departure.departure.time) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        _vm._s(_vm.departure.status.estimatedDeparture.time) +
                          "\n                "
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.departure.departure.time) +
                          "\n                "
                      ),
                    ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "departure-item departure-destination" },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.departure.destination.name) +
                      "\n                "
                  ),
                  _vm.departure.status.remarks
                    ? _c(
                        "span",
                        { staticClass: "departure-remarks-text v-small-hide" },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.departure.status.remarks) +
                              "\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "departure-item departure-status" },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.departure.status.status) +
                      "\n                "
                  ),
                  _vm.departure.position
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "departure-status-position-icon",
                                          style: {
                                            color:
                                              "#" +
                                              _vm.departure.trip.route
                                                .textColor,
                                          },
                                        },
                                        on
                                      ),
                                      [_vm._v("emergency_share")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1827639635
                          ),
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "Real-time location information is available - click row for details."
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "departure-item departure-track" }, [
                _vm.departure.status.track.changed
                  ? _c(
                      "span",
                      { staticStyle: { "text-decoration": "underline" } },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.departure.status.track.track) +
                            "\n                "
                        ),
                      ]
                    )
                  : _vm.departure.status.track.scheduled
                  ? _c("span", { staticStyle: { opacity: "0.5" } }, [
                      _c("em", [
                        _vm._v(_vm._s(_vm.departure.status.track.track)),
                      ]),
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.departure.status.track.track) +
                          "\n                "
                      ),
                    ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "departure-item departure-peak" },
                [
                  _vm.departure.trip.peak
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        {
                                          staticClass: "departure-peak-icon",
                                          style: {
                                            color:
                                              "#" +
                                              _vm.departure.trip.route
                                                .textColor,
                                          },
                                        },
                                        on
                                      ),
                                      [_vm._v("monetization_on")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2256513242
                          ),
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "This departure is on a peak train and a higher fare may be required"
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "departure-item departure-remarks departure-remarks-text",
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.departure.status.remarks) +
                      "\n            "
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-expand-transition",
        [
          _vm.tripDetailsVisible
            ? _c("rt-trip-details", {
                attrs: {
                  trip: _vm.departure.trip,
                  station: _vm.station,
                  position: _vm.departure.position,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }