var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            "mobile-break-point": "960",
            width: "250",
            clipped: "",
            app: "",
          },
          model: {
            value: _vm.drawerVisible,
            callback: function ($$v) {
              _vm.drawerVisible = $$v
            },
            expression: "drawerVisible",
          },
        },
        [
          _c("rt-drawer-menu", {
            attrs: {
              favorites: _vm.favorites.favorites,
              update: _vm.update,
              appUpdate: _vm.appUpdate,
              transitAlertCount: _vm.transitAlertCount,
            },
            on: {
              showDialog: _vm.onShowDialog,
              startUpdate: _vm.onStartUpdate,
              startAppUpdate: _vm.onStartAppUpdate,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-toolbar",
        {
          staticClass: "primary-bg",
          attrs: { id: "app-toolbar", "clipped-left": "", fixed: "", app: "" },
        },
        [
          _c("v-toolbar-side-icon", {
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.toggleDrawer.apply(null, arguments)
              },
            },
          }),
          _vm._v(" "),
          _c("v-fade-transition", [
            (_vm.appUpdate.isAvailable || _vm.update.isAvailable) &&
            !_vm.drawerVisible
              ? _c("span", { staticClass: "toolbar-badge secondary-bg" })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "v-toolbar-title",
            {
              staticClass: "toolbar-title",
              style: { cursor: _vm.agencyId ? "pointer" : "auto" },
              on: { click: _vm.onClickToolbar },
            },
            [_vm._v("\n            " + _vm._s(_vm.toolbarTitle) + "\n        ")]
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _vm.toolbarMenu && _vm.toolbarMenu.length > 0
            ? _c(
                "div",
                [
                  _vm._l(_vm.toolbarMenu, function (item, index) {
                    return [
                      _c(
                        "v-btn",
                        {
                          key: index,
                          attrs: {
                            disabled: item.disabled,
                            dark: "",
                            icon: "",
                          },
                        },
                        [
                          item.type === "icon"
                            ? _c("v-icon", { on: { click: item.function } }, [
                                _vm._v(_vm._s(item.icon)),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.moreMenu && _vm.moreMenu.length > 0
            ? _c(
                "v-menu",
                {
                  attrs: {
                    "allow-overflow": "",
                    absolute: "",
                    top: "",
                    right: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g({ attrs: { dark: "", icon: "" } }, on),
                              [_c("v-icon", [_vm._v("more_vert")])],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1582921727
                  ),
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-list",
                    { attrs: { id: "more-menu-list" } },
                    _vm._l(_vm.moreMenu, function (item) {
                      return _c(
                        "div",
                        { key: item.key },
                        [
                          item.type === "divider" ? _c("v-divider") : _vm._e(),
                          _vm._v(" "),
                          item.type === "divider" && item.title
                            ? _c("v-subheader", [_vm._v(_vm._s(item.title))])
                            : _vm._e(),
                          _vm._v(" "),
                          item.type === "item"
                            ? _c(
                                "v-list-tile",
                                { on: { click: item.function } },
                                [
                                  item.icon
                                    ? _c(
                                        "v-icon",
                                        { staticClass: "more-menu-list-icon" },
                                        [_vm._v(_vm._s(item.icon))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("v-list-tile-title", [
                                    _vm._v(_vm._s(item.title)),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.type === "menu"
                            ? [
                                _c(
                                  "v-menu",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "open-delay": "500",
                                      "open-on-hover": "",
                                      "offset-x": "",
                                      left: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-tile",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: { slot: "activator" },
                                        on: { click: function () {} },
                                        slot: "activator",
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "more-menu-list-icon",
                                          },
                                          [_vm._v("arrow_left")]
                                        ),
                                        _vm._v(" "),
                                        _c("v-list-tile-title", [
                                          _vm._v(_vm._s(item.title)),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-list",
                                      _vm._l(item.items, function (subitem) {
                                        return _c(
                                          "v-list-tile",
                                          {
                                            key: subitem.key,
                                            on: { click: subitem.function },
                                          },
                                          [
                                            subitem.icon
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "more-menu-list-icon",
                                                  },
                                                  [_vm._v(_vm._s(subitem.icon))]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c("v-list-tile-title", [
                                              _vm._v(_vm._s(subitem.title)),
                                            ]),
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-fade-transition",
            [
              _vm.toolbarProgress.isActive
                ? _c("v-progress-linear", {
                    staticClass: "toolbar-progress",
                    attrs: {
                      color: "secondary",
                      height: "5",
                      value: _vm.toolbarProgress.progress,
                      indeterminate: !_vm.toolbarProgress.progress,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-content",
        [
          _c("rt-offline-alert", {
            attrs: {
              visible: !_vm.networkOnline,
              drawerVisible: _vm.drawerVisible,
            },
          }),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _c("router-view", {
                attrs: {
                  updateInfo: _vm.update,
                  transitInfo: _vm.transitInfo,
                  transitFeed: _vm.transitFeed,
                },
                on: {
                  setToolbarMenuItems: _vm.onSetToolbarMenuItems,
                  setMoreMenuItems: _vm.onSetMoreMenuItems,
                  setTitle: _vm.onSetTitle,
                  setProgress: _vm.onSetProgress,
                  setStatusBar: _vm.onSetStatusBar,
                  updateFavorites: _vm.onUpdateFavorites,
                  showDialog: _vm.onShowDialog,
                  showSnackbar: _vm.onShowSnackbar,
                  checkUpdate: _vm.onCheckUpdate,
                  startUpdate: _vm.onStartUpdate,
                  checkAppUpdate: _vm.onCheckAppUpdate,
                  startAppUpdate: _vm.onStartAppUpdate,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.bottomBarEnabled
            ? _c("rt-bottom-bar", {
                attrs: { transitAlertCount: _vm.transitAlertCount },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("rt-confirmation-dialog", { attrs: { properties: _vm.dialog } }),
          _vm._v(" "),
          _c("rt-progress-dialog", { attrs: { properties: _vm.progress } }),
          _vm._v(" "),
          _c("rt-snackbar", { attrs: { properties: _vm.snackbar } }),
        ],
        1
      ),
      _vm._v(" "),
      _c("rt-status-bar", {
        attrs: {
          drawerVisible: _vm.drawerVisible,
          properties: _vm.statusBar,
          transitInfo: _vm.transitInfo,
          transitFeed: _vm.transitFeed,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }