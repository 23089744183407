var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "400", persistent: "", scrollable: "" },
      on: {
        keydown: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.onKeyDownEnter.apply(null, arguments)
          },
          _vm.onKeyDown,
        ],
      },
      model: {
        value: _vm.properties.visible,
        callback: function ($$v) {
          _vm.$set(_vm.properties, "visible", $$v)
        },
        expression: "properties.visible",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "card" },
        [
          _c(
            "v-card-title",
            { staticClass: "headline secondary-bg" },
            [
              _c("v-icon", [_vm._v("list")]),
              _vm._v("  Lookup Train Number\n            "),
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-icon", { on: { click: _vm.close } }, [_vm._v("close")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "card-body" },
            [
              _c("p", [
                _vm._v(
                  "Lookup the scheduled train times in the database by train number and date:"
                ),
              ]),
              _vm._v(" "),
              _c("v-text-field", {
                ref: "trainNumber",
                attrs: { label: "Train Number", required: "" },
                model: {
                  value: _vm.trainNumber,
                  callback: function ($$v) {
                    _vm.trainNumber = $$v
                  },
                  expression: "trainNumber",
                },
              }),
              _vm._v(" "),
              _c("v-text-field", {
                attrs: { label: "Date", required: "" },
                model: {
                  value: _vm.date,
                  callback: function ($$v) {
                    _vm.date = $$v
                  },
                  expression: "date",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticStyle: { "background-color": "#eee" } },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: { click: _vm.trainNumberSelected },
                },
                [_vm._v("Lookup")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }