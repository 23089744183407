var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-container", { staticClass: "page" }, [
    _c("p", [_vm._v("Logging out...")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }