var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "page" },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "secondary-bg" },
            [
              _c("v-icon", { attrs: { large: "", left: "" } }, [
                _vm._v("person_outline"),
              ]),
              _vm._v(" "),
              _c("h2", [_vm._v("Log In")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("p", { staticClass: "subheading" }, [
                _vm._v("\n                Log in to your "),
                _c("strong", [_vm._v("Right Track Account")]),
                _vm._v(" using either your "),
                _c("em", [_vm._v("username")]),
                _vm._v(" or \n                "),
                _c("em", [_vm._v("email address")]),
                _vm._v(".\n            "),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      box: "",
                      "prepend-icon": "person",
                      label: "Username or Email",
                      type: "email",
                      rules: [_vm.rules.required],
                    },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.login.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.user,
                      callback: function ($$v) {
                        _vm.user = $$v
                      },
                      expression: "user",
                    },
                  }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("v-text-field", {
                    attrs: {
                      box: "",
                      "prepend-icon": "lock",
                      label: "Password",
                      type: "password",
                      rules: [_vm.rules.required],
                    },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.login.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.pass,
                      callback: function ($$v) {
                        _vm.pass = $$v
                      },
                      expression: "pass",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "forgot",
                      attrs: { color: "primary", flat: "", small: "" },
                      on: { click: _vm.reset },
                    },
                    [_vm._v("Forgot Password?")]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _c("br"),
                  _vm._v(" "),
                  _c("div", { staticClass: "button-container" }, [
                    _c(
                      "div",
                      { staticClass: "button-login" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              disabled: !_vm.valid || _vm.loggingIn,
                              color: "primary",
                            },
                            on: { click: _vm.login },
                          },
                          [
                            _c("v-icon", [_vm._v("person_outline")]),
                            _vm._v(" Log In\n                        "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "button-register" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              disabled: _vm.loggingIn,
                              color: "primary",
                              outline: "",
                            },
                            on: {
                              click: _vm.register,
                              "update:disabled": function ($event) {
                                _vm.loggingIn = $event
                              },
                            },
                          },
                          [
                            _c("v-icon", [_vm._v("person_add")]),
                            _vm._v(" Create Account\n                        "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }