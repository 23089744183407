var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "trip-details-wrapper" }, [
    _c("div", { staticClass: "trip-details" }, [
      _vm.isDefined
        ? _c("div", { staticClass: "trip-details-info" }, [
            _c(
              "p",
              [
                _c("v-icon", { attrs: { color: "#111" } }, [_vm._v("train")]),
                _vm._v(" Train #" + _vm._s(_vm.trip.shortName)),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "p",
              [
                _c("v-icon", { attrs: { color: "#111" } }, [_vm._v("map")]),
                _vm._v(" " + _vm._s(_vm.trip.route.shortName)),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "p",
              [
                _c("v-icon", { attrs: { color: "#111" } }, [
                  _vm._v("swap_horiz"),
                ]),
                _vm._v(" " + _vm._s(_vm.getDirectionDescription())),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "p",
              [
                _c("v-icon", { attrs: { color: "#111" } }, [
                  _vm._v("pin_drop"),
                ]),
                _vm._v(" From " + _vm._s(_vm.getOriginStopTime().stop.name)),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "p",
              [
                _c("v-icon", { attrs: { color: "#111" } }, [
                  _vm._v("pin_drop"),
                ]),
                _vm._v(" To " + _vm._s(_vm.getDestinationStopTime().stop.name)),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "p",
              [
                _c("v-icon", { attrs: { color: "#111" } }, [
                  _vm._v("monetization_on"),
                ]),
                _vm._v(" "),
                !_vm.trip.peak ? _c("span", [_vm._v("Off ")]) : _vm._e(),
                _vm._v("Peak"),
              ],
              1
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm.position
              ? _c(
                  "p",
                  [
                    _c("v-icon", { attrs: { color: "#111" } }, [
                      _vm._v("emergency_share"),
                    ]),
                    _vm._v(" "),
                    _c("strong", [_vm._v("Current Location:")]),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", { staticClass: "position-description" }, [
                      _vm._v(_vm._s(_vm.position.description)),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isDefined
        ? _c(
            "div",
            { staticClass: "trip-details-stops" },
            _vm._l(_vm.getStopTimes(), function (st, index) {
              return _c(
                "div",
                {
                  key: st.stop.id,
                  staticClass: "trip-details-stops-row",
                  style: _vm.getStopTimeRowStyle(index),
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "trip-details-stops-row-item trip-details-stops-row-time",
                    },
                    [
                      _vm.stopTimeHasDepartureDelay(index)
                        ? _c(
                            "span",
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "v-xsmall-hide",
                                  staticStyle: { float: "left" },
                                  attrs: {
                                    color: _vm.getStopTimeRowTextColor(index),
                                  },
                                },
                                [_vm._v("access_time")]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getStopTimeArrival(index)) +
                                  " "
                              ),
                              _c("br"),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-icon",
                        {
                          staticClass: "v-xsmall-hide",
                          staticStyle: { float: "left" },
                          attrs: { color: _vm.getStopTimeRowTextColor(index) },
                        },
                        [_vm._v("access_time")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(_vm.getStopTimeDeparture(index)) +
                          " \n                "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "trip-details-stops-row-item trip-details-stops-row-stop",
                    },
                    [
                      _vm.stopTimeHasDepartureDelay(index)
                        ? _c("div", { staticClass: "stop-container-both" }, [
                            _c(
                              "div",
                              { staticClass: "stop-icon-arrival" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "v-xsmall-hide",
                                    attrs: {
                                      color: _vm.getStopTimeRowTextColor(index),
                                    },
                                  },
                                  [_vm._v("place")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "stop-name-arrival" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(st.stop.name) +
                                  " (Arrive)\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "stop-icon-departure" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "v-xsmall-hide",
                                    attrs: {
                                      color: _vm.getStopTimeRowTextColor(index),
                                    },
                                  },
                                  [_vm._v("place")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "stop-name-departure" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(st.stop.name) +
                                  " (Depart)\n                        "
                              ),
                            ]),
                          ])
                        : _c("div", { staticClass: "stop-container" }, [
                            _c(
                              "div",
                              { staticClass: "stop-icon" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "v-xsmall-hide",
                                    attrs: {
                                      color: _vm.getStopTimeRowTextColor(index),
                                    },
                                  },
                                  [_vm._v("place")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "stop-name" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(st.stop.name) +
                                  "\n                        "
                              ),
                            ]),
                          ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "trip-details-stops-row-item trip-details-stops-row-traveltime",
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.stopTimesTravelTime[index]) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "trip-details-stops-row-item trip-details-stops-row-ada",
                    },
                    [
                      st.stop.wheelchairBoarding === 1
                        ? _c(
                            "v-icon",
                            {
                              attrs: {
                                color: _vm.getStopTimeRowTextColor(index),
                              },
                            },
                            [_vm._v("accessible")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      st.pickupType == 0 && st.dropOffType == 1
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "span",
                                          _vm._g({}, on),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color:
                                                    _vm.getStopTimeRowTextColor(
                                                      index
                                                    ),
                                                },
                                              },
                                              [_vm._v("train")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-icon",
                                              {
                                                staticStyle: {
                                                  "margin-left": "-10px",
                                                },
                                                attrs: {
                                                  color:
                                                    _vm.getStopTimeRowTextColor(
                                                      index
                                                    ),
                                                },
                                              },
                                              [_vm._v("chevron_left")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "This train is scheduled to only pick up passengers at this stop"
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      st.pickupType == 1 && st.dropOffType == 0
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "span",
                                          _vm._g({}, on),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color:
                                                    _vm.getStopTimeRowTextColor(
                                                      index
                                                    ),
                                                },
                                              },
                                              [_vm._v("train")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-icon",
                                              {
                                                staticStyle: {
                                                  "margin-left": "-10px",
                                                },
                                                attrs: {
                                                  color:
                                                    _vm.getStopTimeRowTextColor(
                                                      index
                                                    ),
                                                },
                                              },
                                              [_vm._v("chevron_right")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "This train is scheduled to only drop off passengers at this stop"
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    !_vm.isDefined
      ? _c("div", { staticClass: "trip-details-unknown" }, [
          _c(
            "p",
            [_c("v-icon", [_vm._v("help_outline")]), _vm._v(" Unknown Trip")],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }