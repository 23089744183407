var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "page" },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline secondary-bg" },
            [
              _c("v-icon", [_vm._v("settings")]),
              _vm._v("  Settings\n        "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "card-content" },
            [
              _c(
                "v-tabs",
                {
                  attrs: {
                    grow: "",
                    "icons-and-text": "",
                    height: "40",
                    color: "secondary-bg",
                  },
                  on: { change: _vm.onTabChange },
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                [
                  _c("v-tabs-slider", { attrs: { color: "primary" } }),
                  _vm._v(" "),
                  _c("v-tab", [_c("v-icon", [_vm._v("save_alt")])], 1),
                  _vm._v(" "),
                  _c("v-tab", [_c("v-icon", [_vm._v("train")])], 1),
                  _vm._v(" "),
                  _c("v-tab", [_c("v-icon", [_vm._v("person")])], 1),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    [
                      _c("rt-settings-updates", {
                        attrs: {
                          agencyId: _vm.agencyId,
                          settings: _vm.settings,
                          isLoggedIn: _vm.isLoggedIn,
                        },
                        on: {
                          refresh: _vm.onRefresh,
                          showSnackbar: _vm.onShowSnackbar,
                          showDialog: _vm.onShowDialog,
                          checkUpdate: _vm.onCheckUpdate,
                          startUpdate: _vm.onStartUpdate,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    [
                      _c("rt-settings-trips", {
                        attrs: {
                          agencyId: _vm.agencyId,
                          settings: _vm.settings,
                          isLoggedIn: _vm.isLoggedIn,
                        },
                        on: {
                          refresh: _vm.onRefresh,
                          showSnackbar: _vm.onShowSnackbar,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    [
                      _c("rt-settings-account", {
                        attrs: {
                          agencyId: _vm.agencyId,
                          settings: _vm.settings,
                          isLoggedIn: _vm.isLoggedIn,
                          user: _vm.user,
                        },
                        on: {
                          refresh: _vm.onRefresh,
                          showSnackbar: _vm.onShowSnackbar,
                          showDialog: _vm.onShowDialog,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }