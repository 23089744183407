var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500", scrollable: "" },
      model: {
        value: _vm.properties.visible,
        callback: function ($$v) {
          _vm.$set(_vm.properties, "visible", $$v)
        },
        expression: "properties.visible",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "card" },
        [
          _c(
            "v-card-title",
            { staticClass: "headline secondary-bg" },
            [
              _c("v-icon", [_vm._v("filter_list")]),
              _vm._v("  Displayed Departures\n            "),
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-icon", { on: { click: _vm.close } }, [_vm._v("close")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "card-body" },
            [
              _c("div", { staticClass: "direction-header" }, [
                _c("p", [_c("strong", [_vm._v("Select Direction:")])]),
              ]),
              _vm._v(" "),
              _c(
                "v-list",
                _vm._l(_vm.directions, function (direction) {
                  return _c(
                    "v-list-tile",
                    {
                      key: direction.id,
                      on: {
                        click: function ($event) {
                          return _vm.selectDirection(direction)
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-tile-content",
                        [
                          _c(
                            "v-list-tile-title",
                            {
                              class: {
                                selected:
                                  _vm.selectedDirection &&
                                  _vm.selectedDirection.id === direction.id,
                              },
                            },
                            [_vm._v(_vm._s(direction.label))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "v-layout",
                { staticClass: "terminal", attrs: { row: "", wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs10: "" } }, [
                    _c("p", [
                      _vm._v("Include Trains that terminate at this station"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("v-flex", {
                    staticClass: "hidden-xs-only",
                    attrs: { xs1: "" },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { xs1: "" } },
                    [
                      _c("v-switch", {
                        staticClass: "mt-1",
                        attrs: { color: "primary" },
                        model: {
                          value: _vm.includeTerminalSwitch,
                          callback: function ($$v) {
                            _vm.includeTerminalSwitch = $$v
                          },
                          expression: "includeTerminalSwitch",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticStyle: { "background-color": "#eee" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c("v-flex"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "secondary-bg",
                      attrs: { color: "secondary" },
                      on: { click: _vm.close },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }