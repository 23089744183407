var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "page" },
    [
      _c(
        "v-card",
        { staticClass: "trip-card" },
        [
          _c(
            "v-card-title",
            { staticClass: "headline secondary-bg" },
            [_c("v-icon", [_vm._v("train")]), _vm._v("  Trips\n        ")],
            1
          ),
          _vm._v(" "),
          _c("v-card-text", [
            _c("h3", [_vm._v("Origin & Destination")]),
            _vm._v(" "),
            _c("div", { staticClass: "origin-destination-container" }, [
              _c(
                "div",
                { staticClass: "origin-container" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        disabled: !_vm.stationDialogProps.stops,
                        depressed: "",
                      },
                      on: { click: _vm.selectOrigin },
                    },
                    [
                      _c("v-icon", [_vm._v("place")]),
                      _vm._v("  \n                        "),
                      _vm.origin
                        ? _c("span", [_vm._v(_vm._s(_vm.origin.name))])
                        : _c("span", [_vm._v("Origin")]),
                      _vm._v("\n                          "),
                      _c("v-icon", [_vm._v("arrow_drop_down")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "origin-destination-arrow" },
                [_c("v-icon", [_vm._v("arrow_forward")])],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "destination-container" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        disabled: !_vm.stationDialogProps.stops,
                        depressed: "",
                      },
                      on: { click: _vm.selectDestination },
                    },
                    [
                      _c("v-icon", [_vm._v("place")]),
                      _vm._v("  \n                        "),
                      _vm.destination
                        ? _c("span", [_vm._v(_vm._s(_vm.destination.name))])
                        : _c("span", [_vm._v("Destination")]),
                      _vm._v("\n                          "),
                      _c("v-icon", [_vm._v("arrow_drop_down")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("h3", [_vm._v("Date & Time")]),
            _vm._v(" "),
            _c("div", { staticClass: "date-time-container" }, [
              _c(
                "div",
                { staticClass: "date-container" },
                [
                  _c(
                    "v-dialog",
                    {
                      ref: "dateDialog",
                      attrs: {
                        "return-value": _vm.dateDialogProps.date,
                        persistent: "",
                        lazy: "",
                        "full-width": "",
                        width: "290px",
                      },
                      on: {
                        "update:returnValue": function ($event) {
                          return _vm.$set(_vm.dateDialogProps, "date", $event)
                        },
                        "update:return-value": function ($event) {
                          return _vm.$set(_vm.dateDialogProps, "date", $event)
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", depressed: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.dateDialogProps.visible = true
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("calendar_today")]),
                                  _vm._v(
                                    "  \n                                "
                                  ),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.departure.date)),
                                  ]),
                                  _vm._v(
                                    "\n                                  "
                                  ),
                                  _c("v-icon", [_vm._v("arrow_drop_down")]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.dateDialogProps.visible,
                        callback: function ($$v) {
                          _vm.$set(_vm.dateDialogProps, "visible", $$v)
                        },
                        expression: "dateDialogProps.visible",
                      },
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-date-picker",
                        {
                          attrs: { scrollable: "" },
                          model: {
                            value: _vm.dateDialogProps.date,
                            callback: function ($$v) {
                              _vm.$set(_vm.dateDialogProps, "date", $$v)
                            },
                            expression: "dateDialogProps.date",
                          },
                        },
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", flat: "" },
                              on: {
                                click: function ($event) {
                                  _vm.dateDialogProps.visible = false
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.updateDate },
                            },
                            [_vm._v("OK")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "time-container" },
                [
                  _c(
                    "v-dialog",
                    {
                      ref: "timeDialog",
                      attrs: {
                        "return-value": _vm.timeDialogProps.time,
                        persistent: "",
                        lazy: "",
                        "full-width": "",
                        width: "290px",
                      },
                      on: {
                        "update:returnValue": function ($event) {
                          return _vm.$set(_vm.timeDialogProps, "time", $event)
                        },
                        "update:return-value": function ($event) {
                          return _vm.$set(_vm.timeDialogProps, "time", $event)
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", depressed: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.timeDialogProps.visible = true
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("access_time")]),
                                  _vm._v(
                                    "  \n                                "
                                  ),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.departure.time)),
                                  ]),
                                  _vm._v(
                                    "\n                                  "
                                  ),
                                  _c("v-icon", [_vm._v("arrow_drop_down")]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.timeDialogProps.visible,
                        callback: function ($$v) {
                          _vm.$set(_vm.timeDialogProps, "visible", $$v)
                        },
                        expression: "timeDialogProps.visible",
                      },
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-time-picker",
                        {
                          attrs: { scrollable: "" },
                          model: {
                            value: _vm.timeDialogProps.time,
                            callback: function ($$v) {
                              _vm.$set(_vm.timeDialogProps, "time", $$v)
                            },
                            expression: "timeDialogProps.time",
                          },
                        },
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", flat: "" },
                              on: {
                                click: function ($event) {
                                  _vm.timeDialogProps.visible = false
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.updateTime },
                            },
                            [_vm._v("OK")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "trips-button-container" },
        [
          _c(
            "v-btn",
            {
              attrs: {
                color: "primary",
                disabled:
                  !_vm.origin ||
                  !_vm.destination ||
                  _vm.origin === _vm.destination,
              },
              on: { click: _vm.showTripResults },
            },
            [_vm._v("\n            Search\n        ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("rt-stop-selection-dialog", {
        attrs: { properties: _vm.stationDialogProps },
        on: { stopSelected: _vm.onStopSelected },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }