var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "page" },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline secondary-bg" },
            [
              _c("v-icon", [_vm._v("help")]),
              _vm._v("  Help & Feedback\n        "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "card-content" },
            [
              _c(
                "v-tabs",
                {
                  attrs: {
                    grow: "",
                    "icons-and-text": "",
                    height: "40",
                    color: "secondary-bg",
                  },
                  on: { change: _vm.onTabChange },
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                [
                  _c("v-tabs-slider", { attrs: { color: "primary" } }),
                  _vm._v(" "),
                  _c("v-tab", [_c("v-icon", [_vm._v("help")])], 1),
                  _vm._v(" "),
                  _c("v-tab", [_c("v-icon", [_vm._v("comment")])], 1),
                  _vm._v(" "),
                  _c("v-tab-item", { staticClass: "tab-item" }, [
                    _c("h3", [_vm._v("General Help")]),
                    _vm._v(" "),
                    _c("p", { staticClass: "question" }, [
                      _vm._v(
                        "The website display is messed up/wonky/not what I'm expecting"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("ol", [
                      _c("li", [
                        _vm._v(
                          "Since this site is using modern web technologies, "
                        ),
                        _c("strong", [
                          _vm._v(
                            "make sure you're using a recent version \n                            of a modern browser"
                          ),
                        ]),
                        _vm._v(
                          ".  Google Chrome, Mozilla Firefox, and their variants work best.  Apple \n                            Safari should work decently.  All others haven't been thoroughly tested."
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v("This site is a "),
                        _c("strong", [_vm._v("Progressive Web App (PWA)")]),
                        _vm._v(
                          " meaning that the code used to display \n                            the site is cached/stored in your browser (for offline use).  After an update to the site, your \n                            browser may still be using (portions of) old code.\n                            "
                        ),
                        _c("ul", [
                          _c("li", [
                            _c("strong", [
                              _vm._v("Try performing a manual refresh"),
                            ]),
                            _vm._v(
                              ".  This can generally be done by holding the \n                                    shift-key while pressing the refresh button (a couple of times for good luck).\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("strong", [
                              _vm._v(
                                "Try clearing the site's cache/data from your browser"
                              ),
                            ]),
                            _vm._v(
                              ".  The steps for this vary \n                                    depending on your browser, but there is generally a function for this by clicking the icon to the \n                                    left of the URL in the browser's location bar.\n                                "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "question" }, [
                      _vm._v("A trip search is showing incorrect times"),
                    ]),
                    _vm._v(" "),
                    _c("ol", [
                      _c("li", [
                        _vm._v(
                          "\n                            Make sure you have the latest agency schedule database installed\n                            "
                        ),
                        _c("ul", [
                          _c("li", [
                            _vm._v("Go to the "),
                            _c("a", { on: { click: _vm.settings } }, [
                              _vm._v("Settings"),
                            ]),
                            _vm._v(" page"),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v("Click the "),
                            _c("strong", [_vm._v("Reset")]),
                            _vm._v(
                              " button to clear the existing schedule database and download the latest schedules"
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v(
                          "\n                            If you have the latest agency schedule database installed and the Trip Results are still displaying \n                            incorrect times, please report the error using the "
                        ),
                        _c("a", { on: { click: _vm.feedback } }, [
                          _vm._v("Feedback Form"),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    { staticClass: "tab-item" },
                    [
                      _c("h3", [_vm._v("Feedback Form")]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "If you have any feedback, such as a bug report, feature suggestion or any other way \n                    to improve the site, you can contact the developer using the form below."
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-form",
                        { ref: "form" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              box: "",
                              "prepend-icon": "email",
                              label: "Email Address (optional)",
                              type: "email",
                            },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.submit.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email = $$v
                              },
                              expression: "email",
                            },
                          }),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              box: "",
                              "prepend-icon": "list",
                              label: "Feedback Type",
                              items: _vm.feedbackTypes,
                            },
                            model: {
                              value: _vm.feedbackType,
                              callback: function ($$v) {
                                _vm.feedbackType = $$v
                              },
                              expression: "feedbackType",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "v-slide-y-transition",
                            { attrs: { mode: "out-in" } },
                            [
                              _vm.feedbackType === "Incorrect Trip Results"
                                ? _c(
                                    "div",
                                    { key: "trip" },
                                    [
                                      _c("p", [
                                        _c("strong", [_vm._v("NOTE:")]),
                                        _vm._v(
                                          " The schedule information shown on the site comes \n                                directly from schedule data provided by the specific agency.  Errors in the \n                                scheduled departure/arrival times and/or missing or incorrect trips will have \n                                to be fixed by the originating agency.  Errors in transfers can be fixed by \n                                this site and suggestions for improving specific transfers are appreciated."
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-select", {
                                        attrs: {
                                          box: "",
                                          "prepend-icon": "location_on",
                                          label: "Origin",
                                          items: _vm.stops,
                                        },
                                        model: {
                                          value: _vm.origin,
                                          callback: function ($$v) {
                                            _vm.origin = $$v
                                          },
                                          expression: "origin",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("v-select", {
                                        attrs: {
                                          box: "",
                                          "prepend-icon": "location_on",
                                          label: "Destination",
                                          items: _vm.stops,
                                        },
                                        model: {
                                          value: _vm.destination,
                                          callback: function ($$v) {
                                            _vm.destination = $$v
                                          },
                                          expression: "destination",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          box: "",
                                          "prepend-icon": "date_range",
                                          label: "Date / Time",
                                        },
                                        model: {
                                          value: _vm.datetime,
                                          callback: function ($$v) {
                                            _vm.datetime = $$v
                                          },
                                          expression: "datetime",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("v-textarea", {
                                        attrs: {
                                          box: "",
                                          "auto-grow": "",
                                          "prepend-icon": "message",
                                          label: "Description",
                                          hint: "Please describe what was (incorrectly) displayed on the site and what you thought should have been displayed.",
                                        },
                                        model: {
                                          value: _vm.tripDescription,
                                          callback: function ($$v) {
                                            _vm.tripDescription = $$v
                                          },
                                          expression: "tripDescription",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.feedbackType === "General Bug Report"
                                ? _c(
                                    "div",
                                    { key: "bug" },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          box: "",
                                          "auto-grow": "",
                                          "prepend-icon": "message",
                                          label: "Bug Description",
                                          hint: "Please describe the bug as detailed as you can (ie steps to produce the problem) and what you thought should have happened.",
                                        },
                                        model: {
                                          value: _vm.bugDescription,
                                          callback: function ($$v) {
                                            _vm.bugDescription = $$v
                                          },
                                          expression: "bugDescription",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.feedbackType === "Feature Suggestion"
                                ? _c(
                                    "div",
                                    { key: "feature" },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          box: "",
                                          "auto-grow": "",
                                          "prepend-icon": "message",
                                          label: "Feature Suggestion",
                                          hint: "Please describe the feature suggestion.",
                                        },
                                        model: {
                                          value: _vm.featureDescription,
                                          callback: function ($$v) {
                                            _vm.featureDescription = $$v
                                          },
                                          expression: "featureDescription",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.feedbackType === "Other"
                                ? _c(
                                    "div",
                                    { key: "other" },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          box: "",
                                          "auto-grow": "",
                                          "prepend-icon": "message",
                                          label: "Other Feedback",
                                        },
                                        model: {
                                          value: _vm.otherDescription,
                                          callback: function ($$v) {
                                            _vm.otherDescription = $$v
                                          },
                                          expression: "otherDescription",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                disabled: !_vm.valid,
                                block: "",
                              },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.submit.apply(null, arguments)
                                },
                                click: _vm.submit,
                              },
                            },
                            [
                              _vm._v(
                                "\n                                Submit\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }